// Explore.js
import React, { useEffect } from 'react';

const Explore = () => {

    useEffect(() => {
        // Replace 'https://api.example.com/data' with your API endpoint
        fetch('http://localhost:3002/wallet3s')
          .then((response) => {
              console.log(response);
              
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
          .then((data) => {
              console.log(data);
            })
          .catch((error) => {
              console.log(error);
              
            });
    }, [])
  return (
    <div>
      <h1>Explore Page</h1>
    </div>
  );
};

export default Explore;
