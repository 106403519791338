// Community.js
import React from 'react';

const Community = () => {
  return (
    <div>
      <h1>Community Page</h1>
    </div>
  );
};

export default Community;
