import React from 'react';
import ConnectWallet from './ConnectWallet';
const App = () => {
  return (
    <div>
      <h1>My Solana DApp</h1>
      <ConnectWallet />
    </div>
  );
};
export default ConnectWallet;
