import React, { useState, useEffect } from 'react';
import { Connection, PublicKey, Transaction, SystemProgram } from '@solana/web3.js';
import "./App.css"
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import Home from './screens/home';
import Explore from './screens/Explore';
import Activity from './screens/Activity';
import Community from './screens/Community';
import Nav from './conponents/nav';
const ConnectWallet = () => {
    const [account, setAccount] = useState(null);
    const [provider, setProvider] = useState(null);
    const connection = new Connection('https://api.mainnet-beta.solana.com');
console.log("account",account);
    useEffect(() => {
        if ('solana' in window) {
            const provider = window.solana;
            if (provider.isPhantom) {
                provider.disconnect()
          
                setProvider(provider);
                provider.on('connect', () => {
                    console.log('CONNECTNTED');
            
                    setAccount(provider.publicKey.toString());
                });
                provider.on('disconnect', () => {
                    console.log('disconnected');
            
                    setAccount(null);
                });
            }
        } else {
            window.open('https://phantom.app/', '_blank');
        }
    }, []);

    const connectWallet = async () => {
        console.log('Provider', provider);
      
        if (provider) {
            try {
                await provider.connect();
            } catch (error) {
                console.error('User denied account access or error occurred: ', error);
            }
        }
    };

    const sendTransaction = async () => {
        if (provider && account) {
            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: provider.publicKey,
                    toPubkey: new PublicKey('RecipientPublicKeyHere'),
                    lamports: 1000000, // 1 SOL = 1e9 lamports
                }),
            );

            try {
                const { blockhash } = await connection.getRecentBlockhash();
                transaction.recentBlockhash = blockhash;
                transaction.feePayer = provider.publicKey;

                const signed = await provider.signTransaction(transaction);
                const signature = await connection.sendRawTransaction(signed.serialize());
                await connection.confirmTransaction(signature);
                console.log('Transaction successful with signature: ', signature);
            } catch (error) {
                console.error('Transaction failed: ', error);
            }
        }
    };
   
    return (
         <Router>
        <div className="app-container">
            <header className="header">
                <div className="logo">AB-Studio</div>
                <nav className="nav">
                  
        <Nav/>
                </nav>
                <div className="actions">
                    <input type="text" placeholder="Search..." className="search-input" />
                    <button onClick={connectWallet} onPaste={connectWallet}  className="wallet-connect"> {account?  account : "Wallet Connect"} </button>
                </div>
            </header>
            <main className="main-content">
         
                </main>
                        <Routes>

                     <Route path="/" element={<Home />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/community" element={<Community />} />
          {/* <Route path="/nft-collection" element={<NFTCollection />} /> */}
        </Routes>
            </div>
            </Router>
    );
}
export default ConnectWallet;
