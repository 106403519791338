// Activity.js
import React, { useEffect, useState } from 'react';

const Activity = () => {
    const [address, setAddress] = useState('');
    const [message, setMessage] = useState('');
    useEffect(() => {
  handleSubmit()
    }, [])
    console.log(message);
    const handleSubmit = async (e) => {
        try {
            const response = await fetch('http://localhost:3002/wallet', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ address :"mywalletmortezamoradiaddress" }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setMessage(`Wallet added with ID: ${data.id}`);
            setAddress('');
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
    };
  return (
    <div>
      <h1>Activity Page</h1>
    </div>
  );
};

export default Activity;
