// Home.js
import React from 'react';
import "../App.css"
import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';
import Counter from '../conponents/countdown/CountDown';

const Home = () => {

 
    
  return (
    <div>
           <div className='main-row'>
   <Counter/>
                <div className="main-text">
                    <h1><span className="highlight">Digital Sculpture</span> NFT Collection</h1>
                    <p>NFT Is A Unit Of Data Stored On A Digital Ledger Called A Blockchain That Certifies A Digital Asset To Be Unique.</p>
                    <div className="buttons">
                        <button className="primary-button">Started NFT Collection</button>
                        <button  className="secondary-button">Play Now</button>
                    </div>
                </div>
                <div className="main-image">
                    <img   src={require("../assets/image2.jpg")} alt="Digital Sculpture" />
                    </div>
                </div>
                    
                <div className="bid-section">
                    <span>Render 3D Artworks</span>
                    <span>2.00ETH</span>
                    <button>Place Bid</button>
                </div>
         <section className="artwork-section">
          <h2 className="artwork-title">NFT Art Work</h2>
          <p className="artwork-description">
            NFTs are part of the Ethereum blockchain.
          </p>
          <div className="artwork-cards">
            <div className="card">
           <img src={require("../assets/image1.jpg")} alt="Artwork 1" className="card-image" />
              <h3 className="card-title">GRIM SYNDICATE</h3>
              <p className="card-subtitle">REAPER AGENT</p>
            </div>
            <div className="card">
              <img src={require("../assets/image3.jpg") }alt="Artwork 2" className="card-image" />
              <h3 className="card-title">GRIM SYNDICATE</h3>
              <p className="card-subtitle">REAPER AGENT</p>
            </div>
          </div>
        </section>
    </div>
  );
};

export default Home;
