import React from "react"
import { Link, useLocation } from "react-router-dom"
import "../App.css"
const Nav = () => {
      const location = useLocation();

    return (
           <nav className="nav">
          <Link to="/" className={location.pathname === '/' ? 'active' : 'nav-link'}>Home 1</Link>
          <Link to="/explore" className={location.pathname === '/explore' ? 'active' : 'nav-link'}>Explore</Link>
          <Link to="/activity" className={location.pathname === '/activity' ? 'active' : 'nav-link'}>Activity</Link>
          <Link to="/community" className={location.pathname === '/community' ? 'active' : 'nav-link'}>Community</Link>
        </nav>
    )
}

export default Nav