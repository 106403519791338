import React from 'react';
import Countdown from 'react-countdown';
import './CountDown.css';

const Counter = () => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <div className="countdown-container">Time's up!</div>;
    } else {
      return (
        <div className="countdown-container">
          <div className="countdown-item">
            <span>{days}</span>
            <div className="countdown-item-label">Days</div>
          </div>
          <div className="countdown-item">
            <span>{hours}</span>
            <div className="countdown-item-label">Hours</div>
          </div>
          <div className="countdown-item">
            <span>{minutes}</span>
            <div className="countdown-item-label">Minutes</div>
          </div>
          <div className="countdown-item">
            <span>{seconds}</span>
            <div className="countdown-item-label">Seconds</div>
          </div>
        </div>
      );
    }
  };

  return (
    <Countdown
      date={Date.now() + 100000000}
      intervalDelay={0}
      precision={3}
      renderer={renderer}
    />
  );
};

export default Counter;
